// @ts-strict-ignore
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import {
  user as apiUser,
  getState as apiGetState,
  setState as apiSetState,
  userKeys as apiUserKeys,
  update as apiUpdate,
  signup as apiSignup,
  signin as apiSignin,
  signout as apiSignout,
  getForumLogin as apiGetForumLogin,
  resetPasswordStart as apiResetPasswordStart,
  resetPasswordFinish as apiResetPasswordFinish,
  DashboardState,
} from '../../api/user';
import { UserResponse, UserAccountProfile, UpdateUserRequest } from '../../../types/routes/user';
import { getAll as getClasses, RejectedPayload } from './class';
import { debugAuth } from '../../../settings';

interface SignupArgs {
  name: string | null;
  emails: string[];
  password: string;
  firstName: string | null;
  middleName: string | null;
  lastName: string | null;
  profile: UserAccountProfile;
}

interface SigninArgs {
  email: string;
  password: string;
}

export const onLocationChange = createAction('user/on-location-change');

export const cypressChangeCookieExpiration = createAction('user/cypress-quick-expire');

export const user = createAsyncThunk<UserResponse, void, { rejectValue: RejectedPayload }>(
  'user',
  async ({}, { dispatch, rejectWithValue }) => {
    try {
      if (debugAuth) console.log('*** user route start');
      const result = await apiUser();
      // await this to simplify fetchedUser logic and avoid double user fetch
      await dispatch(getState());
      await dispatch(getClasses());
      // dispatch any other login data requests here
      if (debugAuth) console.log('*** user route success, user id', result.id);
      return result;
    } catch (error) {
      if (debugAuth) console.log('*** user route error', error);
      return rejectWithValue(error.response ? { ...error.response.data, httpCode: error.response.status } : error);
    }
  },
);

export const getState = createAsyncThunk('user/state', async (): Promise<DashboardState> => {
  return apiGetState();
});

export const setState = createAsyncThunk('user/tutorial-video', async (data: DashboardState) => {
  /*
   * Currrently, there is only one quantity in the state.
   * More generally, we would want to merge with other quantities
   * in the object.
   */
  return apiSetState(data);
});

export const userKeys = createAsyncThunk('users/keys', async () => {
  return apiUserKeys();
});

export const signup = createAsyncThunk<UserResponse, SignupArgs, { rejectValue: RejectedPayload }>(
  'users/signup',
  async ({ name, emails, password, firstName, middleName, lastName, profile }, { rejectWithValue }) => {
    try {
      const user = await apiSignup({ name, firstName, middleName, lastName, emails, password, profile });
      const result = await apiSignin({ email: user.email, password });
      return result;
    } catch (error) {
      return rejectWithValue(error.response ? { ...error.response.data, httpCode: error.response.status } : error);
    }
  },
);

export const updateProfile = createAsyncThunk<UserResponse, UpdateUserRequest, { rejectValue: RejectedPayload }>(
  'users/update',
  async ({ profile }, { rejectWithValue }) => {
    try {
      const result = await apiUpdate({ profile });
      return result;
    } catch (error) {
      return rejectWithValue(error.response ? { ...error.response.data, httpCode: error.response.status } : error);
    }
  },
);

export const updateProfileUi = createAction<UpdateUserRequest>('users/updateProfileUi');

export const signin = createAsyncThunk<
  UserResponse,
  SigninArgs,
  {
    rejectValue: RejectedPayload;
  }
>('users/signin', async ({ email, password }: SigninArgs, { dispatch, rejectWithValue }) => {
  try {
    const result = await apiSignin({ email, password });
    // await this to simplify fetchedUser logic and avoid double user fetch
    await dispatch(getState());
    await dispatch(getClasses());
    return result;
  } catch (error) {
    if (debugAuth) console.log('*** signin route error', error);
    return rejectWithValue(error.response ? { ...error.response.data, httpCode: error.response.status } : error);
  }
});

export const signout = createAsyncThunk('users/signout', async () => {
  window.localStorage.removeItem('customPersistIdleTimer');
  await apiSignout();
});

export const resetPasswordStart = createAsyncThunk<
  null,
  string,
  {
    rejectValue: RejectedPayload;
  }
>('users/resetPasswordStart', async (email: string, { rejectWithValue }) => {
  try {
    await apiResetPasswordStart({ email });
  } catch (error) {
    return rejectWithValue(error.response ? { ...error.response.data, httpCode: error.response.status } : error);
  }
});

export const resetPasswordFinish = createAsyncThunk(
  'users/resetPasswordFinish',
  async ({ token, password }: { token: string; password: string }) => {
    await apiResetPasswordFinish({ token, password });
  },
);

export const resetPasswordReset = createAction('resetPasswordReset');

export const getForumCredentials = createAsyncThunk('get-forum-credentials', async () => {
  return apiGetForumLogin();
});
