import { Record, Static, Boolean, Null } from 'runtypes';
import { routes } from '../../types/routes';
import {
  SignupRequest,
  SigninRequest,
  SignoutResponse,
  UserResponse,
  UpdateUserRequest,
  UserKeyResponse,
  UserResponseRecord,
  UserKeyResponseRecord,
  SignoutResponseRecord,
  ResetPasswordStartRequest,
  ResetPasswordStartResponse,
  ResetPasswordFinishRequest,
  ResetPasswordFinishResponse,
  TeacherForumResponseRecord,
  TeacherForumResponse,
} from '../../types/routes/user';
import { makeRequest } from '.';

/*
 * On the server, the state is arbitrary, accepting only
 * allowed names.  However, we do fix the format on the
 * teacher dashboard.
 */
const DashboardStateRecord = Record({
  tutorialVideoViewed: Boolean,
}).Or(Null);
export type DashboardState = Static<typeof DashboardStateRecord>;

export const signup = async (data: SignupRequest): Promise<UserResponse> => {
  const response = await makeRequest(routes.signup.method, routes.signup.path, data);
  return UserResponseRecord.check(response.data);
};

export const signin = async (data: SigninRequest): Promise<UserResponse> => {
  const response = await makeRequest(routes.signin.method, routes.signin.path, data);
  return UserResponseRecord.check(response.data);
};

export const signout = async (): Promise<SignoutResponse> => {
  const response = await makeRequest(routes.signout.method, routes.signout.path);
  return SignoutResponseRecord.check(response.data);
};

export const user = async (): Promise<UserResponse> => {
  const response = await makeRequest(routes.user.method, routes.user.path);
  return UserResponseRecord.check(response.data);
};

export const update = async (data: UpdateUserRequest): Promise<UserResponse> => {
  const response = await makeRequest(routes.updateUser.method, routes.updateUser.path, data);
  return UserResponseRecord.check(response.data);
};

export const getState = async (): Promise<DashboardState> => {
  const query = new URLSearchParams();
  query.append('name', 'teacher-dashboard');
  const response = await makeRequest(routes.getState.method, routes.getState.path + '?' + query);
  return DashboardStateRecord.check(response.data.value);
};
export const setState = async (value: DashboardState): Promise<DashboardState> => {
  const data = { name: 'teacher-dashboard', value };
  const response = await makeRequest(routes.setState.method, routes.setState.path, data);
  return DashboardStateRecord.check(response.data.value);
};

export const userKeys = async (): Promise<UserKeyResponse> => {
  const response = await makeRequest(routes.userKeys.method, routes.userKeys.path);
  return UserKeyResponseRecord.check(response.data);
};

export const getForumLogin = async (): Promise<TeacherForumResponse> => {
  const response = await makeRequest(routes.getForumLogin.method, routes.getForumLogin.path);
  return TeacherForumResponseRecord.check(response.data);
};

export const resetPasswordStart = async (data: ResetPasswordStartRequest): Promise<ResetPasswordStartResponse> => {
  const response = await makeRequest(routes.resetPasswordStart.method, routes.resetPasswordStart.path, data);
  return response.data;
};

export const resetPasswordFinish = async (data: ResetPasswordFinishRequest): Promise<ResetPasswordFinishResponse> => {
  const response = await makeRequest(routes.resetPasswordFinish.method, routes.resetPasswordFinish.path, data);
  return response.data;
};
