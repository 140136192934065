// @ts-strict-ignore
import { concat, findIndex } from 'lodash';
import { createReducer } from '@reduxjs/toolkit';
import {
  get as getClass,
  getAll as getClasses,
  getExternal,
  create,
  importExternal,
  edit,
  remove,
  resetExternalGet,
  updateExternal,
  resetExternalUpdate,
  resetExternalImport,
  onLocationChange,
  resetGet,
  resetCreate,
  resetEdit,
  resetRemove,
  resetGetError,
  updateLicense,
  resetUpdateLicense,
  resetGetLearnerTypes,
  getLearnerTypes,
  updateLearnerType,
  resetupdateLearnerType,
  resetGetStudentUsage,
  getStudentUsage,
  getClassState,
  pushOrInsertClassState,
  editNickName,
} from '../actions/class';
import { update as updateClassroom } from '../actions/classroom';
import { getEvents } from '../actions/module';
import { ExternalClass } from '../../../types/routes/external';
import { GetStudentUsageResponse } from '../../../types/routes/module';
import { Class as ClassWithStudents, ClassStateResponse } from '../../../types/routes/class';

export interface State {
  fetchingClass: boolean;
  fetchingClasses: boolean;
  fetchingExternalClasses: boolean;
  updatingExternalClasses: boolean;
  importingExternalClasses: boolean;
  studentUsage: GetStudentUsageResponse;
  fetchingStudentUsage: boolean;
  hasStudentUsageError: boolean;
  studentUsageErrorMessage: string | null;
  creatingClass: boolean;
  editingClass: boolean;
  removingClass: boolean;
  updatingClassroom: boolean;
  classes: ClassWithStudents[];
  externalClasses: ExternalClass[];
  externalSource: string | null;
  externalInstance: string | null;
  hasError: boolean;
  hasGetError: boolean;
  hasShownGetError: boolean;
  isChangingLicenseStatus: boolean;
  errorMessage: string | null;
  fetchingLearnerTypes: boolean;
  postingLearnerType: boolean;
  learnerTypes: {
    classId: number;
    userId: number;
    firstLearnerType: string | null;
    secondLearnerType: string | null;
  }[];
  fetchingClassState: boolean;
  fetchingEvents: boolean;
  postingClassState: boolean;
  classState: ClassStateResponse[];
}

const studentUsageEmpty = {
  students: {},
};

const initialState: State = {
  fetchingClass: false,
  fetchingClasses: true,
  fetchingExternalClasses: false,
  updatingExternalClasses: false,
  importingExternalClasses: false,
  studentUsage: studentUsageEmpty,
  fetchingStudentUsage: true,
  hasStudentUsageError: false,
  studentUsageErrorMessage: null,
  creatingClass: false,
  editingClass: false,
  removingClass: false,
  updatingClassroom: false,
  classes: [],
  externalClasses: [],
  externalSource: null,
  externalInstance: null,
  hasError: false,
  hasGetError: false, // this is used to display the error in the sidebar nav.
  hasShownGetError: false, // this is used to show the error only once.
  isChangingLicenseStatus: false,
  errorMessage: null,
  fetchingLearnerTypes: false,
  postingLearnerType: false,
  learnerTypes: [],
  fetchingClassState: false,
  fetchingEvents: false,
  postingClassState: false,
  classState: [],
};

const setErrorState = (state, action) => {
  if (action.payload && action.payload.noPopup) {
    return;
  }
  state.hasError = true;
  if (action.payload) {
    state.errorMessage = action.payload.message;
  } else {
    if (typeof action.error === 'string' && action.error.length > 0) {
      state.errorMessage = action.error;
    } else {
      state.errorMessage = 'Unknown error.';
    }
  }
};

const clearErrorState = (state: State) => {
  state.hasError = false;
  state.errorMessage = null;
};

const setStudentUsageError = (state: State, action) => {
  state.hasStudentUsageError = true;
  if (action.payload) {
    state.studentUsageErrorMessage = action.payload.message;
  } else {
    if (typeof action.error === 'string' && action.error.length > 0) {
      state.studentUsageErrorMessage = action.error;
    } else {
      state.studentUsageErrorMessage = 'Unknown error.';
    }
  }
};

export default createReducer<State>(initialState, (builder) =>
  builder
    .addCase(onLocationChange, (state) => {
      clearErrorState(state);
    })
    .addCase(getClass.pending, (state) => {
      state.fetchingClass = true;
    })
    .addCase(getClass.fulfilled, (state, action) => {
      state.classes = [...state.classes.filter((c) => c.id != action.payload.id), action.payload];
      state.fetchingClass = false;
    })
    .addCase(getClass.rejected, (state, action) => {
      setErrorState(state, action);
      state.fetchingClass = false;
    })
    .addCase(getClasses.pending, (state) => {
      state.fetchingClasses = true;
      state.classes = [];
    })
    .addCase(getClasses.fulfilled, (state, action) => {
      state.classes = action.payload.classes;
      state.fetchingClasses = false;
      state.hasShownGetError = false;
    })
    .addCase(getClasses.rejected, (state, action) => {
      setErrorState(state, action);
      state.hasError = false;
      state.classes = [];
      state.fetchingClasses = false;
      state.hasGetError = true;
    })
    .addCase(resetGet, (state) => {
      state.fetchingClasses = false;
      state.hasShownGetError = true;
      clearErrorState(state);
    })
    .addCase(resetGetError, (state) => {
      state.hasGetError = false;
      clearErrorState(state);
    })

    .addCase(getExternal.pending, (state) => {
      state.fetchingExternalClasses = true;
      state.externalClasses = [];
      state.externalSource = null;
      state.externalInstance = null;
    })
    .addCase(getExternal.fulfilled, (state, action) => {
      state.externalClasses = action.payload.data;
      state.externalSource = action.payload.externalSource;
      state.externalInstance = action.payload.externalInstance;
      state.fetchingExternalClasses = false;
    })
    .addCase(getExternal.rejected, (state, action) => {
      setErrorState(state, action);
      state.externalClasses = [];
      state.externalSource = null;
      state.externalInstance = null;
      state.fetchingExternalClasses = false;
    })
    .addCase(resetExternalGet, (state) => {
      state.fetchingExternalClasses = false;
      clearErrorState(state);
    })

    .addCase(create.pending, (state) => {
      state.creatingClass = true;
    })
    .addCase(create.fulfilled, (state, action) => {
      state.classes = concat(state.classes, action.payload);
    })
    .addCase(create.rejected, (state, action) => {
      setErrorState(state, action);
      state.classes = [];
      state.creatingClass = false;
    })
    .addCase(resetCreate, (state) => {
      state.creatingClass = false;
      clearErrorState(state);
    })

    .addCase(importExternal.pending, (state) => {
      state.importingExternalClasses = true;
    })
    .addCase(importExternal.fulfilled, (state, action) => {
      state.classes = concat(state.classes, action.payload);
      state.importingExternalClasses = false;
    })
    .addCase(importExternal.rejected, (state, action) => {
      setErrorState(state, action);
      state.importingExternalClasses = false;
    })
    .addCase(resetExternalImport, (state) => {
      state.importingExternalClasses = false;
      clearErrorState(state);
    })

    .addCase(updateExternal.pending, (state) => {
      state.updatingExternalClasses = true;
    })
    .addCase(updateExternal.fulfilled, (state, action) => {
      const classIndex = findIndex(state.classes, (c) => c.id === action.payload.id);
      state.classes[classIndex] = action.payload;
      state.updatingExternalClasses = false;
    })
    .addCase(updateExternal.rejected, (state, action) => {
      setErrorState(state, action);
      state.updatingExternalClasses = false;
    })
    .addCase(resetExternalUpdate, (state) => {
      state.updatingExternalClasses = false;
      clearErrorState(state);
    })

    .addCase(edit.pending, (state) => {
      state.editingClass = true;
    })
    .addCase(edit.fulfilled, (state, action) => {
      // This does not create a new classes array
      const classIndex = findIndex(state.classes, (c) => c.id === action.payload.id);
      state.classes[classIndex] = action.payload;
    })
    .addCase(edit.rejected, (state, action) => {
      setErrorState(state, action);
      state.creatingClass = false;
    })
    .addCase(resetEdit, (state) => {
      state.editingClass = false;
      clearErrorState(state);
    })

    .addCase(editNickName.fulfilled, (state, action) => {
      // This creates a new classes array
      state.classes = state.classes.map((c) =>
        c.id == action.payload.id ? { ...c, nickName: action.payload.nickName } : c,
      );
    })

    .addCase(remove.pending, (state) => {
      state.removingClass = true;
    })
    .addCase(remove.fulfilled, (state) => {
      state.removingClass = false;
      // we now re-load the class list in actions.
    })
    .addCase(remove.rejected, (state, action) => {
      setErrorState(state, action);
      state.creatingClass = false;
    })
    .addCase(resetRemove, (state) => {
      state.removingClass = false;
      clearErrorState(state);
    })

    .addCase(updateClassroom.pending, (state) => {
      state.updatingClassroom = true;
    })
    .addCase(updateClassroom.fulfilled, (state, action) => {
      const { id, classIds } = action.payload;
      const classes = state.classes || [];
      state.classes = classes.map((c: ClassWithStudents) => {
        // Create a new object any time there is a change in that object.
        if (classIds.includes(c.id)) {
          if (c.classroomId != id) {
            return { ...c, classroomId: id };
          }
        } else if (c.classroomId == id) {
          return { ...c, classroomId: null };
        }
        return c;
      });
      state.updatingClassroom = false;
    })
    .addCase(updateClassroom.rejected, (state, action) => {
      setErrorState(state, action);
      state.updatingClassroom = false;
    })

    .addCase(updateLicense.pending, (state) => {
      state.isChangingLicenseStatus = true;
    })
    .addCase(updateLicense.fulfilled, (state, action) => {
      /* This may be triggered for a student in
       * state.accountManagement.classesWithUsers
       */
      const c = state.classes.find((c) => c.id == action.payload.classId);
      if (c) {
        const s = c.students.find((s) => s.id == action.payload.studentId);
        s.licenseStatus = action.payload.licenseStatus;
      }
      // The update license action will dispatch an update to
      // the associated contracts.
      state.isChangingLicenseStatus = false;
    })
    .addCase(updateLicense.rejected, (state, action) => {
      setErrorState(state, action);
      state.isChangingLicenseStatus = false;
    })
    .addCase(resetUpdateLicense, (state) => {
      clearErrorState(state);
      state.isChangingLicenseStatus = false;
    })

    .addCase(getLearnerTypes.pending, (state) => {
      state.fetchingLearnerTypes = true;
    })
    .addCase(getLearnerTypes.fulfilled, (state, action) => {
      state.fetchingLearnerTypes = false;
      state.learnerTypes = action.payload;
    })
    .addCase(getLearnerTypes.rejected, (state, action) => {
      setErrorState(state, action);
      state.fetchingLearnerTypes = false;
    })
    .addCase(resetGetLearnerTypes, (state) => {
      clearErrorState(state);
      state.fetchingLearnerTypes = false;
    })

    .addCase(updateLearnerType.pending, (state) => {
      state.postingLearnerType = true;
    })
    .addCase(updateLearnerType.fulfilled, (state, action) => {
      state.postingLearnerType = false;
      state.learnerTypes = state.learnerTypes.filter((learnerType) => learnerType.userId !== action.payload.userId);
      state.learnerTypes.push(action.payload);
    })
    .addCase(updateLearnerType.rejected, (state, action) => {
      setErrorState(state, action);
      state.postingLearnerType = false;
    })
    .addCase(resetupdateLearnerType, (state) => {
      clearErrorState(state);
      state.postingLearnerType = false;
    })
    .addCase(getClassState.pending, (state) => {
      state.fetchingClassState = true;
    })
    .addCase(getClassState.fulfilled, (state, action) => {
      classStateUpdate(state, action.payload);
      state.fetchingClassState = false;
    })
    .addCase(getClassState.rejected, (state, action) => {
      setErrorState(state, action);
      state.fetchingClassState = false;
    })
    .addCase(pushOrInsertClassState.pending, (state) => {
      state.postingClassState = true;
    })
    .addCase(pushOrInsertClassState.fulfilled, (state, action) => {
      classStateUpdate(state, action.payload);
      state.postingClassState = false;
    })
    .addCase(pushOrInsertClassState.rejected, (state, action) => {
      setErrorState(state, action);
      state.postingClassState = false;
    })
    .addCase(getEvents.pending, (state) => {
      state.fetchingEvents = true;
    })
    .addCase(getEvents.fulfilled, (state, action) => {
      // Use relevant log events (sent from the VR) to update the state table
      const { events, after } = action.payload;
      // We are only interested in event updates.
      // Assume that the class state is requested in parallel with the
      // initial event request.
      if (after) {
        for (const event of events) {
          if (event.action == 'set-state') {
            const classStatePointer = state.classState.find((cs) => cs.classId == event.classId);
            const classState: ClassStateResponse = classStatePointer || { classId: event.classId, userStates: [] };
            for (const [name, value] of Object.entries(event.properties.update)) {
              const index = classState.userStates.findIndex((cs) => cs.userId == event.userId && cs.name == name);
              const newState = { userId: event.userId, name, value, createdAt: event.createdAt };
              if (index == -1) classState.userStates.push(newState);
              else if (classState.userStates[index].createdAt < event.createdAt)
                classState.userStates[index] = newState;
            }
            if (!classStatePointer) state.classState.push(classState);
          }
        }
      }
      state.fetchingEvents = false;
    })
    .addCase(getEvents.rejected, (state) => {
      state.fetchingEvents = false;
    })
    .addCase(getStudentUsage.pending, (state) => {
      state.fetchingStudentUsage = true;
    })
    .addCase(getStudentUsage.fulfilled, (state, action) => {
      state.studentUsage = action.payload;
      state.fetchingStudentUsage = false;
    })
    .addCase(getStudentUsage.rejected, (state, action) => {
      setStudentUsageError(state, action);
      state.fetchingStudentUsage = false;
    })
    .addCase(resetGetStudentUsage, (state) => {
      state.studentUsage = studentUsageEmpty;
      state.fetchingStudentUsage = false;
      state.hasStudentUsageError = false;
      state.studentUsageErrorMessage = null;
    }),
);

const classStateUpdate = (state: State, payload: ClassStateResponse) => {
  state.classState = state.classState.filter((cs) => cs.classId != payload.classId);
  // Order such that class-wise values to occur before user overrides.
  payload.userStates.sort((a, b) => {
    if (a.userId === b.userId) return 0;
    if (a.userId === null) return -1;
    if (b.userId === null) return 1;
    return 0;
  });
  state.classState.push(payload);
};
