// @ts-strict-ignore
import { TaskDefinition, VersionDefinition } from '../../../types/routes/module';
import { ClassStateResponse, Student } from '../../../types/routes/class';
import { ModuleEvent, ModuleEventWithUserClass, UserStateName } from '../../../types/models';
import {
  StudentStatus,
  ErrorPrints,
  StudentOverrides,
  UserWithClassId,
  ActiveRun,
  TaskState,
  Screencast,
  ScreencastStatus,
} from './types';
import { Dictionary, flatten, fromPairs, isEqual, isNil } from 'lodash';
import { ScreencastModelTypes, modelTypeName } from '../../../types/core';

// Time interval, in seconds, to look for the most recent module events.
const DELAY_TIME = 30 * 60;

export type RunStats = { date: Date; percentageComplete: number };

/*
 * Ignore "message read" events from teacher.
 * Ignore "start run" events since they occur outside a task
 * (this will suppress the "tut-welcome" errors seen September 2023)
 */
export const getLastEvent = (events: ModuleEventWithUserClass[]) => {
  return events.findLast(
    (event: ModuleEventWithUserClass) =>
      event.userId &&
      event.moduleId &&
      event.taskId &&
      event.runId &&
      event.version &&
      event.action != 'start run' &&
      'time' in event.properties,
  );
};

export function getRunStats(tasks: TaskDefinition[], events: ModuleEvent[], lastEvent: ModuleEvent): RunStats {
  const finishedTaskIds = new Set<string>();
  let started = false;
  events.forEach((e) => {
    if (tasks.some((task) => e.taskId == task.id) && e.moduleId == lastEvent.moduleId && e.runId == lastEvent.runId) {
      if (e.action === 'started') started = true;
      else if (e.action === 'finished') finishedTaskIds.add(e.taskId);
    }
  });
  if (started) {
    const date = lastEvent.createdAt;
    const percentageComplete = (100.0 * finishedTaskIds.size) / tasks.length;
    return { date, percentageComplete };
  } else {
    return { date: null, percentageComplete: 0 };
  }
}

export const getRunName = ({
  tasks,
  events,
  lastEvent,
  index,
}: {
  tasks: TaskDefinition[];
  events: ModuleEvent[];
  lastEvent: ModuleEvent;
  index: number;
}): string => {
  const { percentageComplete, date } = getRunStats(tasks, events, lastEvent);
  const dater = date ? '(' + date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' }) + ')' : '';
  return `Run ${index + 1}: ${percentageComplete.toFixed(0)}% ${dater}`;
};

// dt is in seconds
// Assume events are chronological
export const getStudentIdle = (events: ModuleEventWithUserClass[], before: number | null = null): boolean => {
  if (events.length > 0) {
    for (let index = events.length - 1; index >= 0; index--) {
      const lastEvent = events[index];
      // Ignore "message read" for student -> teacher message.
      if ('time' in lastEvent.properties) {
        const now = before ?? Date.now();
        return now - lastEvent.createdAt.getTime() > DELAY_TIME * 1000;
      }
    }
  }
  return true;
};

export const getWatchAvailability = (events: ModuleEvent[]): boolean => {
  const lastWatchEvent = events.findLast(
    (event) => event.action === 'watch busy' || event.action === 'watch available',
  );
  if (lastWatchEvent) return lastWatchEvent.action === 'watch available';
  return false;
};

// given info about a student's status and an active run, returns the active task index or 0
const getActiveTaskIndex = (
  statusStarted: boolean,
  events: ModuleEventWithUserClass[],
  lastEvent: ModuleEventWithUserClass,
  activeRun: ActiveRun,
  partTasks?: TaskDefinition[],
): number => {
  if (statusStarted) {
    const newModuleState: { [taskId: string]: TaskState } = fromPairs(
      partTasks.map((task) => [task.id, { started: 0, finished: 0, count: 1 }]),
    );
    const thisRunEvents =
      lastEvent && activeRun
        ? events.filter((e) => e.moduleId == lastEvent.moduleId && e.runId == activeRun.selected.runId)
        : [];
    for (const event of thisRunEvents) {
      if (event.taskId in newModuleState) {
        if (event.action == 'started') newModuleState[event.taskId].started = 1;
        else if (event.action == 'finished') newModuleState[event.taskId].finished = 1;
      }
    }
    let act = partTasks.findLast(
      (task: TaskDefinition) => newModuleState[task.id].started && !newModuleState[task.id].finished,
    );
    if (!act) {
      act = partTasks.findLast((task: TaskDefinition) => newModuleState[task.id].finished);
    }
    if (act) {
      return partTasks.indexOf(act);
    }
  }
  return -1;
};

export const getStudentStatus = (
  student: Student,
  lastEvent: ModuleEventWithUserClass | null,
  eventsForStudent: ModuleEventWithUserClass[],
  moduleVersionDefinitions: {
    [version: string]: VersionDefinition;
  },
  [errorPrints, setErrorPrints]: ErrorPrints,
  before: number | null,
): StudentStatus => {
  const ev = eventsForStudent;
  if (
    lastEvent &&
    lastEvent.version &&
    'moduleId' in lastEvent &&
    lastEvent.version in moduleVersionDefinitions &&
    moduleVersionDefinitions[lastEvent.version].modules.every((m) => m.id != lastEvent.moduleId)
  ) {
    const ref = lastEvent.version + ':' + lastEvent.moduleId;
    if (!(ref in errorPrints)) {
      const message = `Module ${lastEvent.moduleId} not found in version ${lastEvent.version}`;
      console.log('***** ' + message + `, student ${student.id}`);
      console.log(`******* event ${JSON.stringify(lastEvent)}`);
      setErrorPrints({ ...errorPrints, [ref]: message });
    }
  }
  const module =
    (lastEvent &&
      lastEvent.version &&
      moduleVersionDefinitions[lastEvent.version]?.modules.find((m) => m.id === lastEvent.moduleId)) ||
    null;
  const partIndex =
    module && lastEvent
      ? module.parts.findIndex((part) => part.tasks.map((t) => t.id).includes(lastEvent.taskId))
      : null;
  const part = typeof partIndex == 'number' && partIndex >= 0 ? module.parts[partIndex] : null;
  if (typeof partIndex == 'number' && partIndex < 0) {
    const ref = lastEvent.version + ':' + module.id + ':' + lastEvent.taskId;
    if (!(ref in errorPrints)) {
      const message = `Task ${lastEvent.taskId} not found in module ${module.id} ${lastEvent.version}`;
      setErrorPrints({ ...errorPrints, [ref]: message });
    }
  }
  const task = (lastEvent && part?.tasks.find((t) => t.id === lastEvent.taskId)) || null;
  let activeRun: ActiveRun | null = null;
  if (lastEvent) {
    activeRun = { selected: lastEvent, last: lastEvent };
  }
  // Filter preserves order.
  const eventsForRun =
    lastEvent && part
      ? eventsForStudent.filter(
          (e) =>
            e.runId === lastEvent.runId &&
            e.moduleId == lastEvent.moduleId &&
            part.tasks.some((task) => task.id == e.taskId),
        )
      : [];
  const started = eventsForRun.some((event) => event.action == 'started');
  const finishedTasks = new Set<string>();
  for (const event of eventsForRun) {
    // Only include tasks that are found in the current module version
    if (event.action == 'finished' && part.tasks.some((t) => t.id == event.taskId)) finishedTasks.add(event.taskId);
  }
  const activeTaskIndex = activeRun
    ? getActiveTaskIndex(started, eventsForStudent, lastEvent, activeRun, part?.tasks)
    : -1;
  let activeTaskLabel = '-';
  if (activeTaskIndex > -1 && part) {
    let count = 0;
    for (const task of part.tasks.slice(0, activeTaskIndex)) {
      if (task.sceneType !== 'immersive') count++;
    }
    activeTaskLabel = part.tasks[activeTaskIndex].sceneType === 'immersive' ? '0' : String(count + 1);
  }

  const names: string[] = [];
  if (task && module) {
    names.push(module.name);
    if (part && part.modulePart != 0) {
      names.push('Part ' + part.modulePart);
    }
  }
  const displayName: string = names.length > 0 ? names.join(', ') : null;
  const totalPartTaskCount: number = part?.tasks.length || null;

  const headsetBatteryEvent = ev.findLast(
    (e) =>
      e.action === 'login' || (e.action === 'application-settings' && !isNil(e.properties.update?.headsetBatteryLevel)),
  );
  const leftControllerBatteryEvent = ev.findLast(
    (e) =>
      e.action === 'login' ||
      (e.action === 'application-settings' && !isNil(e.properties.update?.leftControllerBatteryLevel)),
  );
  const rightControllerBatteryEvent = ev.findLast(
    (e) =>
      e.action === 'login' ||
      (e.action === 'application-settings' && !isNil(e.properties.update?.rightControllerBatteryLevel)),
  );
  const headsetBatteryLevel = headsetBatteryEvent
    ? headsetBatteryEvent.action === 'login'
      ? headsetBatteryEvent.properties.headsetBatteryLevel
      : headsetBatteryEvent.properties.update.headsetBatteryLevel
    : undefined;
  const leftControllerBatteryLevel = leftControllerBatteryEvent
    ? leftControllerBatteryEvent.action === 'login'
      ? leftControllerBatteryEvent.properties.leftControllerBatteryLevel
      : leftControllerBatteryEvent.properties.update.leftControllerBatteryLevel
    : undefined;
  const rightControllerBatteryLevel = rightControllerBatteryEvent
    ? rightControllerBatteryEvent.action === 'login'
      ? rightControllerBatteryEvent.properties.rightControllerBatteryLevel
      : rightControllerBatteryEvent.properties.update.rightControllerBatteryLevel
    : undefined;

  /*
   *                             Screencast
   */
  const screencast: Screencast = { available: false, status: ScreencastStatus.none };
  // Determine if screencasting is available
  const lastLoginEvent = ev.findLast((e) => e.action == 'login');
  if (lastLoginEvent) {
    if ('modelType' in lastLoginEvent.properties && 'hardwareType' in lastLoginEvent.properties) {
      const modelType = lastLoginEvent.properties['modelType'];
      if (ScreencastModelTypes.has(modelType)) {
        screencast.available = true;
      } else {
        const name = modelType in modelTypeName ? modelTypeName[modelType] : 'unknown';
        screencast.message = `Screencasting is not available for ${name}`;
      }
    } else {
      screencast.message = 'Screencasting is not available\nfor this version of the VR application';
    }
  } else {
    screencast.message = 'The user is not logged in';
  }
  // Find the most recent screencast request since the most recent login.
  ev.findLast((e: ModuleEvent): boolean => {
    if (e.action === 'login') return true;
    if (
      e.action === 'command-sent' &&
      e.properties.states.find((nv: { name: string; value: any }): boolean => {
        if (nv.name == 'screencast') {
          if (nv.value) screencast.requestId = nv.value.requestId;
          return true;
        }
        return false;
      })
    )
      return true;
    return false;
  });
  // Look for the most recent screencast response event for
  // this request id.
  // Testing with Luis on July 1 showed the response events can get
  // batched and sent out of order if there are multiple rapid-fire requests.
  if (screencast.requestId) {
    ev.findLast((e: ModuleEvent): boolean => {
      if (e.action === 'login') return true;
      if (e.action === 'application-settings') {
        const update = e.properties.update;
        if (update.screencastSuccess && update.screencastSuccess.requestId == screencast.requestId) {
          screencast.url = update.screencastSuccess.url;
          screencast.status = ScreencastStatus.success;
          return true;
        }
        const ref = 'screencast:' + student.id;
        if (
          !(ref in errorPrints) &&
          update.screencastError &&
          update.screencastError.requestId == screencast.requestId
        ) {
          const message = update.screencastError.message;
          setErrorPrints({ ...errorPrints, [ref]: message });
          screencast.message = message;
          screencast.status = ScreencastStatus.error;
          return true;
        }
      }
      return false;
    });
  }

  const result: StudentStatus = {
    idle: getStudentIdle(eventsForStudent, before),
    finishedTaskSet: finishedTasks,
    started,
    // Only include messages in current run, since
    // these are the only ones that *can* be read.
    unreadMessages: eventsForRun.filter(
      (e) =>
        e.action === 'message sent' &&
        e.properties.sender === 'student' &&
        !eventsForRun.some(
          /*
           * Include timestamp for backwards compatibility
           * We are now using "id" instead.
           */
          (e2) =>
            e2.action === 'message read' &&
            ('id' in e2.properties ? e2.properties.id == e.id : e2.properties.timestamp === e.properties.time),
        ),
    ),
    module,
    part,
    partIndex: partIndex || 0,
    activeTaskIndex,
    activeTaskLabel,
    totalPartTaskCount,
    displayName,
    isWatchAvailable: getWatchAvailability(eventsForStudent),
    // This is the version associated with the most current log entry.
    // This is suitable for determining available features.
    version: ev.length > 0 ? ev[ev.length - 1].version : null,
    overrides: new Set<StudentOverrides>(),
    headsetBatteryLevel,
    leftControllerBatteryLevel,
    rightControllerBatteryLevel,
    screencast,
  };
  if (false && student.id == 1692) {
    const ref = `events:${student.id}:${module ? module.id : '*'}:${lastEvent?.id || '*'}`;
    if (!(ref in errorPrints)) {
      const message = `Status ${student.id} ${ref}`;
      console.log('***** ' + message, result);
      setErrorPrints({ ...errorPrints, [ref]: message });
    }
  }
  return result;
};

// Translates the setting state types into the three override icons we use
const overrideStates = {
  [UserStateName.language]: StudentOverrides.settings,
  [UserStateName.accessibility]: StudentOverrides.settings,
  [UserStateName.launchIntoModule]: StudentOverrides.launch,
  [UserStateName.pauseAtTasks]: StudentOverrides.pause,
};

// Given a student and some class/event info, grabs an array of student statuses
// accessible by their user ids. Then, update the statuses with the correct
// setting override values (in-place)
export const getStudentStatuses = (
  students: UserWithClassId[],
  eventsByStudent: Dictionary<ModuleEventWithUserClass[]>,
  classStates: ClassStateResponse[],
  moduleVersionDefinitions: {
    [version: string]: VersionDefinition;
  },
  errorList: ErrorPrints,
  before: number | null,
): { [id: number]: StudentStatus } => {
  // by setting moduleId and modulePart to null, studentStatus will return the most recent module and part.
  const statusByStudent = students.reduce((obj, student) => {
    const lastEvent = student.id in eventsByStudent ? getLastEvent(eventsByStudent[student.id]) : null;
    obj[student.id] = getStudentStatus(
      student,
      lastEvent,
      eventsByStudent[student.id] || [],
      moduleVersionDefinitions,
      errorList,
      before,
    );
    return obj;
  }, {});

  updateStudentStatusOverrides(classStates, statusByStudent);

  return statusByStudent;
};
/*
  given class and student status, update the statusByStudent array in-place with
  override values compared with the class settings. The rules are as follows (using 'launch'
  type as an example):
  Class has “None” launch task (or nothing selected), but student has one - override
  Class has launch task, and student has same one - NO override
  Class has launch task, and student has different one - override
  Class has launch task, and student has “None” - NO override
  Neither has task - NO override
*/
export const updateStudentStatusOverrides = (
  classStates: ClassStateResponse[],
  statusByStudent: { [id: number]: StudentStatus },
) => {
  // get class settings
  const classwideSettings = classStates.filter((css) => css.userStates.map((us) => !us.userId));
  const classwideSettingStates = flatten(classwideSettings.map((css) => css.userStates.filter((us) => !us.userId)));
  // Only count certain state names,
  // combining the langauge menu states.
  for (const css of classStates)
    for (const us of css.userStates)
      if (us.userId !== null && us.value !== null && us.name in overrideStates) {
        const status = Object.keys(statusByStudent).length > 0 ? statusByStudent[us.userId] : undefined;
        if (status) {
          // if user setting is same as class setting, omit override
          const matchingClassSetting =
            classwideSettingStates.length > 0
              ? classwideSettingStates.filter((cws) => cws.name === us.name)[0]
              : undefined;
          // if this user setting exists but no class default does - add to override.
          if (matchingClassSetting) {
            // here, check if 1) setting keys match, and 2) setting values match EXCEPT for expiration date
            const settingKeysMatch = isEqual(Object.keys(matchingClassSetting.value), Object.keys(us.value));
            // if the settings are of the same type and value, they "reconcile". IE they are the same
            // and should not be added to the overrides.
            const userSettingReconcilesWithClass =
              settingKeysMatch &&
              Object.keys(matchingClassSetting.value)
                .filter((k) => k !== 'expiresAt')
                .map((settingKey) => {
                  return isEqual(matchingClassSetting.value[settingKey], us.value[settingKey]);
                })
                .reduce((p, c) => p && c) === true;
            if (userSettingReconcilesWithClass) continue;
          }
        }
      }
};

export const getEnabledFeaturesForStudent = (
  studentStatus: StudentStatus,
  moduleVersionDefinitions: {
    [version: string]: VersionDefinition;
  },
  moduleDefinitions: VersionDefinition,
): string[] => {
  return !studentStatus.idle && studentStatus.version in moduleVersionDefinitions
    ? moduleVersionDefinitions[studentStatus.version].features
    : moduleDefinitions
    ? moduleDefinitions.features
    : [];
};
