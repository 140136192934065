// @ts-strict-ignore
import { omit } from 'lodash';
import roman from 'roman-numerals';
import { ModuleDefinition } from '../../types/routes/module';
import { ModuleEvent } from '../../types/models';
import { assetServer } from '../../config-public';

export const moduleImageURL = `https://${assetServer}/module`;

export const getModuleName = (module: ModuleDefinition, part: number): string => {
  let result = module.name;
  if (part > 0) result += ` Part ${roman.toRoman(part)}`;
  if (module.subtitle) result + ':  ' + module.subtitle;
  return result;
};

/*
 * Backwards-compatible fix for badly formatted
 *  "message sent" on server.  Fixed at the end of 2021.
 */
export const fixMangledModuleEvents = <T extends ModuleEvent>(events: T[]): T[] => {
  return events.map((event) => {
    if (event.action == 'message sent' && event.properties.sender == 'teacher' && 'time' in event.properties) {
      const timestamp = event.properties.time;
      return {
        ...event,
        properties: {
          ...omit(event.properties, 'time'),
          timestamp,
        },
      };
    }
    return event;
  });
};

export class ModuleFormat {
  modules: ModuleDefinition[];

  constructor(modules: ModuleDefinition[]) {
    this.modules = modules;
  }

  name(moduleId: string): string {
    const m = this.modules.find((m) => m.id == moduleId);
    return m ? m.name : '';
  }
  partName(moduleId: string, part: number) {
    const m = this.modules.find((m) => m.id == moduleId);
    if (m) return m.parts.length > 1 ? m.name + ' Part ' + part : m.name;
    return '';
  }
  taskName(moduleId: string, taskId: string) {
    const m = this.modules.find((m) => m.id == moduleId);
    if (m) {
      const p = m.parts.find((p) => p.tasks.map((x) => x.id).includes(taskId));
      if (p) {
        const tIndex = p.tasks.findIndex((t) => t.id == taskId);
        if (tIndex < 0) return '';
        const t = p.tasks[tIndex];
        const taskNumberString = `Task ${tIndex + 1}`;
        const multiplePartString = ' Part ' + p.modulePart;
        return `${taskNumberString}, ${t.name}\n${m.name}${m.parts.length > 1 ? multiplePartString : ''} `;
      }
    }
    return '';
  }
}

export const assetLinkFromAlignedResourcesGoogleDocs = (alignedResourcesLink: string) => {
  const baseUrl = 'https://docs.google.com/document/d/';
  if (alignedResourcesLink.includes(baseUrl)) {
    const googleDocID = alignedResourcesLink.split(baseUrl)[1].split('/')[0];
    return `https://${assetServer}/toolkits/modules/` + googleDocID + '.pdf';
  } else {
    return null;
  }
};
