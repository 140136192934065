import { Record, Partial, String, Number, Boolean, Static, Null, Undefined, Array, Union, Literal } from 'runtypes';
import { Route } from './utils';
import { StateRecord } from './class';

export const userRoutes = (): { [name: string]: Route } => ({
  signup: {
    method: 'post',
    path: '/user/signup',
    requestBody: SignupRequestRecord,
    responseData: UserResponseRecord,
  },
  signin: {
    method: 'post',
    path: '/user/signin',
    requestBody: SigninRequestRecord,
    responseData: UserResponseRecord,
  },
  signout: {
    method: 'get',
    path: '/user/signout',
    requestBody: SignoutRequestRecord,
    responseData: SignoutResponseRecord,
  },
  user: {
    method: 'get',
    path: '/user',
    requestBody: UserRequestRecord,
    responseData: UserResponseRecord,
  },
  updateUser: {
    method: 'patch',
    path: '/user',
    requestBody: UpdateUserRequestRecord,
    responseData: UserResponseRecord,
  },
  getState: {
    method: 'get',
    path: '/user/state',
    requestBody: Record({}),
    responseData: StateRecord,
  },
  setState: {
    method: 'patch',
    path: '/user/state',
    requestBody: StateRecord,
    responseData: StateRecord,
  },
  userKeys: {
    method: 'get',
    path: '/user-keys',
    requestBody: UserRequestRecord,
    responseData: UserKeyResponseRecord,
  },
  getForumLogin: {
    method: 'post',
    path: '/get-forum-credentials',
    requestBody: TeacherForumRequestRecord,
    responseData: TeacherForumResponseRecord,
  },
  resetPasswordStart: {
    method: 'post',
    path: '/user/reset-password-start',
    requestBody: ResetPasswordStartRequestRecord,
    responseData: ResetPasswordStartResponseRecord,
  },
  resetPasswordFinish: {
    method: 'post',
    path: '/user/reset-password-finish',
    requestBody: ResetPasswordFinishRequestRecord,
    responseData: ResetPasswordFinishResponseRecord,
  },
  removeUserClasses: {
    method: 'put',
    path: '/user/:userId/remove-classes',
    requestBody: Record({}),
    responseData: RemoveUserClassesResponseRecord,
  },
  // This should only be used for testing
  deleteUser: {
    method: 'delete',
    path: '/user/:userId',
    requestBody: DeleteUserRequestRecord,
    responseData: DeleteUserResponseRecord,
  },
  authClever: {
    method: 'get',
    path: '/auth/clever',
    requestBody: Record({}),
    responseData: Record({}),
  },
  authCanvas: {
    method: 'get',
    path: '/auth/canvas',
    requestBody: Record({}),
    responseData: Record({}),
  },
  authGoogle: {
    method: 'get',
    path: '/auth/google',
    requestBody: Record({}),
    responseData: Record({}),
  },
  authIbmSam: {
    method: 'get',
    path: '/auth/ibm-sam',
    requestBody: Record({}),
    responseData: Record({}),
  },
  authClassLink: {
    method: 'get',
    path: '/auth/class-link',
    requestBody: Record({}),
    responseData: Record({}),
  },
  authLti: {
    method: 'get',
    path: '/auth/lti',
    requestBody: Record({}),
    responseData: Record({}),
  },
});

// This should match UserRole in types/models.ts
const UserRoleRecord = Union(Literal('teacher'), Literal('admin'));
// This should match UserAccountStatusRecord in types/models.ts
export const UserAccountStatusRecord = Union(Literal('active'), Literal('disabled'), Literal('pending'));
/*
 * The Django admin dashboard can make this
 * into arbitrary JSON.  The server should fix any
 * incompatible format silently.
 */
export const UserAccountProfileRecord = Partial({
  school: String,
  district: String,
  profileRole: String,
  directLoginDisabled: Boolean,
});

const InstitutionRoleRecord = Record({
  institutionId: Number,
  // This should match InstitutionUserRole in types/models.ts
  roles: Array(Union(Literal('institutionAdmin'), Literal('teacher'))),
});

export const SignupRequestRecord = Record({
  name: String.Or(Null),
  firstName: String.Or(Null),
  middleName: String.Or(Null),
  lastName: String.Or(Null),
  emails: Array(String), // So as not to confuse with users.email
  password: String,
  profile: UserAccountProfileRecord,
});

const UpdateUserRequestRecord = Record({
  profile: UserAccountProfileRecord,
});
export const SigninRequestRecord = Record({
  email: String,
  password: String,
});

export const SignoutRequestRecord = Record({});

export const SignoutResponseRecord = Record({});

export const UserRequestRecord = Record({});

export const TeacherForumRequestRecord = Record({});
export const TeacherForumResponseRecord = Record({ username: String, password: String });

// This should match ExternalSource in types/models.ts
export const ExternalSourceRecord = Union(
  Literal('clever'),
  Literal('moodle'),
  Literal('canvas'),
  Literal('google'),
  Literal('oneRoster'),
  Literal('lti'),
  Literal('Pico'),
  Literal('Quest'),
  Literal('Unknown'),
  Literal('Mailer'),
);

export const UserResponseRecord = Record({
  id: Number,
  status: UserAccountStatusRecord,
  name: String.Or(Null),
  firstName: String.Or(Null),
  middleName: String.Or(Null),
  lastName: String.Or(Null),
  roles: Array(UserRoleRecord),
  institutionRoles: Array(InstitutionRoleRecord),
  email: String,
  emails: Array(String),
  externalSource: ExternalSourceRecord.Or(Null),
  externalInstance: String.Or(Null),
  profile: UserAccountProfileRecord,
});

const UserKeyDataRecord = Record({
  id: Number,
  key: String,
  externalName: String,
  nickName: String.Or(Null),
  section: String.Or(Null),
  role: String,
});
export const UserKeyResponseRecord = Array(UserKeyDataRecord);

export const ResetPasswordStartRequestRecord = Record({
  email: String,
});

export const ResetPasswordStartResponseRecord = Record({});

export const ResetPasswordFinishRequestRecord = Record({
  token: String,
  password: String,
});

export const ResetPasswordFinishResponseRecord = Record({});
export const DeleteUserRequestRecord = Record({});
export const DeleteUserResponseRecord = Undefined.Or(Null);
export const RemoveUserClassesResponseRecord = Undefined.Or(Null);

export type SignupRequest = Static<typeof SignupRequestRecord>;
export type SigninRequest = Static<typeof SigninRequestRecord>;
export type SignoutRequest = Static<typeof SignoutRequestRecord>;
export type SignoutResponse = Static<typeof SignoutResponseRecord>;
export type UserAccountStatus = Static<typeof UserAccountStatusRecord>;
export type UserRequest = Static<typeof UserRequestRecord>;
export type UserResponse = Static<typeof UserResponseRecord>;
export type UpdateUserRequest = Static<typeof UpdateUserRequestRecord>;
export type UserAccountProfile = Static<typeof UserAccountProfileRecord>;
export type ExternalSource = Static<typeof ExternalSourceRecord>;
export type UserKeyResponse = Static<typeof UserKeyResponseRecord>;
export type ResetPasswordStartRequest = Static<typeof ResetPasswordStartRequestRecord>;
export type ResetPasswordStartResponse = Static<typeof ResetPasswordStartResponseRecord>;
export type ResetPasswordFinishRequest = Static<typeof ResetPasswordFinishRequestRecord>;
export type ResetPasswordFinishResponse = Static<typeof ResetPasswordFinishResponseRecord>;
export type TeacherForumResponse = Static<typeof TeacherForumResponseRecord>;
