// @ts-strict-ignore
import React, { useState, useEffect, useRef, useCallback } from 'react';
import styled from '@emotion/styled';
import { Text, textStyle } from '../text';
import { black, primary } from '../../utils/colors';
import { ModuleDefinition, Part, TaskDefinition } from '../../../types/routes/module';
import { useSelector } from 'react-redux';
import { SubjectLeafProps } from '../SubjectTree';
import { ShowSetSelectedTask, ModulePartTaskSelection } from '../TaskSelector';
import { CustomTooltip as Tooltip } from '../../components/tooltip';

export const SubjectTaskSelector = ({ subjectModules, leafData }: SubjectLeafProps<ShowSetSelectedTask>) => {
  const query = new URLSearchParams(window.location.search);
  const debug = query.get('debug') ? true : false;
  const taskRef = useRef(undefined);
  const modules = useSelector((state) => state.module.definitions.modules);
  const { showTasks, setSelectedTask, selectedTask, firstTaskIsPart, setConfirmModalState } = leafData;

  const [, updateState] = useState(undefined);
  const forceUpdate = useCallback(() => updateState({}), []);

  const isModuleOpened = (module: ModuleDefinition): boolean => {
    const statePath = getStatePath(module.subjectId, module.id);
    const state = JSON.parse(window.localStorage.getItem(statePath)) || {
      open: false,
    };
    return state.open;
  };
  const isPartOpened = (module: ModuleDefinition, part: Part): boolean => {
    const statePath = getStatePath(module.subjectId, module.id, part.modulePart);
    const state = JSON.parse(window.localStorage.getItem(statePath)) || {
      open: false,
    };
    return state.open;
  };

  // Modules without a toolkit have already been filtered out
  const leafModules: (ModuleDefinition | undefined)[] = subjectModules.map((moduleId) =>
    modules.find((m) => m.id == moduleId),
  );

  useEffect(() => {
    if (taskRef && taskRef.current) {
      taskRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, []);

  // get string key for localStorage open/close setting of given subject, module, and part (if it's present).
  const getStatePath = (subjectId: string, moduleId?: string, partNumber?: number): string => {
    let statePath = `task-selector/subject/${subjectId}`;
    if (moduleId) {
      statePath += `/module/${moduleId}`;
      if (partNumber) {
        statePath += `/part/${partNumber}`;
      }
    }
    return statePath;
  };

  // Use localStorage instead of REACT state mechanism
  const clickHandlerPart = (event: React.MouseEvent<HTMLElement>, module: ModuleDefinition, part?: Part) => {
    // for toggling a details element, do not open or close based on click
    event.preventDefault();

    const statePath = getStatePath(module.subjectId, module.id, part?.modulePart);
    const state = JSON.parse(window.localStorage.getItem(statePath)) || {
      open: false,
    };
    state.open = !state.open;
    window.localStorage.setItem(statePath, JSON.stringify(state));
    // We need to force the component to re-render the list once an item
    // expands or is collapsed, so this empty state change forces that.
    forceUpdate();
  };

  const clickHandlerTask = (module: ModuleDefinition, part: Part, task: TaskDefinition, index: number) => {
    if (showTasks) {
      const mp: ModulePartTaskSelection = {
        moduleId: module.id,
        part: part.modulePart,
      };
      if (index > 0 || !firstTaskIsPart) mp.taskId = task.id;
      setSelectedTask(mp);
    }
  };

  const PartTasks = ({ module, part }: { module: ModuleDefinition; part: Part }) => {
    let count = 1;
    return (
      <StyledTaskList>
        {showTasks &&
          (module.parts.length === 1 || isPartOpened(module, part)) &&
          part.tasks.map((task: TaskDefinition | undefined, index: number) => {
            const selected =
              module.id === selectedTask?.moduleId &&
              part.modulePart === selectedTask?.part &&
              (task.id === selectedTask?.taskId || (!selectedTask.taskId && index === 0));
            // Always enable index zero of task list, then if taskFilter is set, apply the filter. Otherwise, return all tasks.
            const taskEnabled = leafData.taskFilter ? (index == 0 ? true : leafData.taskFilter(task)) : true;
            const uniqueTooltipId = module.id + '-' + part.modulePart + '-' + task.id;
            return (
              task && (
                <div key={task.id} ref={selected && taskRef ? taskRef : null} id={uniqueTooltipId}>
                  <Title
                    selected={selected}
                    onClick={() => {
                      if (taskEnabled) {
                        const clickHandler = () => clickHandlerTask(module, part, task, index);
                        !setConfirmModalState
                          ? clickHandler()
                          : setConfirmModalState({
                              open: true,
                              onConfirm: clickHandler,
                            });
                      }
                    }}
                  >
                    {('sceneType' in task && task.sceneType == 'immersive' ? '' : '' + count++ + '. ') +
                      task.name +
                      (debug && 'snapshot' in task ? ' Snapshot:' + task.snapshot : '')}
                  </Title>
                  {!taskEnabled && (
                    <Tooltip anchorSelect={`[id = "${uniqueTooltipId}"]`} place="top">
                      <Text variant="p">Launch at task is currently disabled for this task.</Text>
                    </Tooltip>
                  )}
                </div>
              )
            );
          })}
      </StyledTaskList>
    );
  };

  const RenderSinglePartModule = ({ module }: { module: ModuleDefinition }) => {
    const selected = !showTasks && module.id == selectedTask?.moduleId;
    return (
      <Module open={isModuleOpened(module)}>
        <Title
          selected={selected}
          onClick={(e) => {
            clickHandlerPart(e, module);
          }}
        >
          {module.name}
        </Title>
        <ModuleContent ref={selected ? taskRef : null}>
          {showTasks && <PartTasks module={module} part={module.parts[0]} />}
        </ModuleContent>
      </Module>
    );
  };

  if (leafModules.length > 0)
    return (
      <Modules>
        {debug && <Text variant="nav">{JSON.stringify({ showTasks, selectedTask })}</Text>}
        {leafModules.map((module: ModuleDefinition | undefined) => (
          <div key={module.id}>
            {module && module.parts.length == 1 && <RenderSinglePartModule module={module} />}
            {module && module.parts.length > 1 && (
              <Module open={isModuleOpened(module)}>
                <Title
                  onClick={(e) => {
                    clickHandlerPart(e, module);
                  }}
                >
                  {module.name}
                </Title>
                <ModuleContent>
                  {module.parts &&
                    module.parts.map((part: Part | undefined) => {
                      const selected =
                        !showTasks && module.id === selectedTask?.moduleId && part.modulePart === selectedTask?.part;
                      return (
                        part && (
                          <StyledPart
                            ref={selected ? taskRef : null}
                            key={part.modulePart}
                            open={isPartOpened(module, part)}
                          >
                            <Title
                              selected={selected}
                              onClick={(e) => {
                                clickHandlerPart(e, module, part);
                              }}
                            >
                              {'Part ' + part.modulePart}
                            </Title>
                            <PartTasks module={module} part={part} />
                          </StyledPart>
                        )
                      );
                    })}
                </ModuleContent>
              </Module>
            )}
          </div>
        ))}
      </Modules>
    );
};

const Modules = styled.div({
  display: 'flex',
  flexDirection: 'column',
  padding: '0',
  paddingTop: '1rem',
});

const Module = styled('details')({
  position: 'relative',
  margin: '2px',
  cursor: 'pointer',
});

const ModuleContent = styled.div({
  textDecoration: 'none',
  // The bottom padding gives room for the button bar.
  padding: '2px',
  minWidth: '10rem',
  display: 'block',
});

const Title = styled('summary')<{ selected?: boolean }>(
  {
    ...textStyle('md'),
    marginBottom: '.5rem',
    '&:hover': {
      color: primary,
    },
  },
  ({ selected }) =>
    selected
      ? {
          color: primary,
        }
      : {
          color: black,
        },
);

const StyledPart = styled('details')({
  marginLeft: '1.3rem',
  '&:hover': {
    color: primary,
  },
});

const StyledTaskList = styled.div({
  marginLeft: '1.3rem',
  '&:hover': {
    color: primary,
  },
});
