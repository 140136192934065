// @ts-strict-ignore
import styled from '@emotion/styled';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Dictionary as DictionaryRecord,
  Record,
  Array,
  Number as NumberRecord,
  String as StringRecord,
  Static,
} from 'runtypes';

import { Text } from '../../components/text';
import { ModuleDefinition } from '../../../types/routes/module';
import { red } from '../../utils/colors';
import { UserStateName } from '../../../types/models';
import { Class as ClassWithStudents, ClassStateResponse, Student } from '../../../types/routes/class';
import { pushOrInsertClassState } from '../../redux/actions/class';
import { TextField } from '../../components/textField';
import { Button } from '../../components/button';

interface Props {
  currentClass: ClassWithStudents;
  student: Student;
  module: ModuleDefinition;
  points: QuizItem;
}

export interface QuizItem {
  points: number;
  modulePart: number;
}

const ModuleAssessmentRecord = DictionaryRecord(
  Array(Record({ score: NumberRecord, total: NumberRecord, modulePart: NumberRecord })),
  StringRecord,
);
type ModuleAssessment = Static<typeof ModuleAssessmentRecord>;

export const QuizScore = ({ currentClass, student, module, points }: Props) => {
  const debugPrint = false;
  const dispatch = useDispatch();

  const [inputState, setInputState] = useState<boolean>(false);
  const [inputErrorState, setInputErrorState] = useState<boolean>(false);

  // The associated dispatch call is in the index.ts file.
  let moduleAssessments: ModuleAssessment = {};
  const allClassStates = useSelector((state) => state.class.classState);
  const classStateLoading = useSelector((state) => state.class.fetchingClassState || state.class.postingClassState);
  const thisClassState: ClassStateResponse = allClassStates.find((cs) => cs.classId == currentClass.id);
  if (thisClassState) {
    for (const us of thisClassState.userStates) {
      if (
        us.userId == student.id &&
        us.name == UserStateName.moduleAssessment &&
        ModuleAssessmentRecord.guard(us.value)
      ) {
        moduleAssessments = ModuleAssessmentRecord.check(us.value);
      }
    }
  }

  const getScore = (percent = false): string => {
    const moduleId = module.id;
    if (moduleId in moduleAssessments) {
      for (const quiz of moduleAssessments[moduleId]) {
        if (quiz.modulePart == points.modulePart) {
          const score = quiz.score;
          if (percent) return '' + Math.round((100 * score) / points.points) + '%';
          else return String(score);
        }
      }
    }
    // if there is no value in the box, allow input
    if (!inputState && !classStateLoading) {
      if (debugPrint) console.log(`*** getScore set input state true ${student.id}`);
      setInputState(true);
    }
    return '';
  };

  const saveScore = (value: string): boolean => {
    if (debugPrint) console.log(`*** saving ${value} for ${student.id} inputState ${inputState}`);
    const numberValue = Number(value);
    if (isNaN(numberValue) || numberValue < 0 || numberValue > points.points) {
      if (!inputErrorState) setInputErrorState(true);
      return false;
    }
    const tma = [];
    for (const a of moduleAssessments[module.id] || []) {
      if (a.modulePart != points.modulePart) tma.push(a);
    }
    tma.push({ score: numberValue, total: points.points, modulePart: points.modulePart });
    const updatedModuleAssessments: ModuleAssessment = {
      ...moduleAssessments,
      [module.id]: tma,
    };
    dispatch(
      pushOrInsertClassState({
        classId: currentClass.id,
        push: true,
        undoOverrides: false,
        data: [
          {
            userId: student.id,
            name: UserStateName.moduleAssessment,
            value: updatedModuleAssessments,
          },
        ],
      }),
    );
    if (inputState) {
      if (debugPrint) console.log(`*** saveScore set input state false ${student.id}`);
      setInputState(false);
    }
    if (inputErrorState) setInputErrorState(false);
    return true;
  };

  const inputId = `${student.id}-${module.id}-${points.modulePart}-quiz-input`;
  const scoreText: string = inputState ? '/' + points.points : getScore(true);

  // The input element is material UI.  Setting the color is not obvious
  return (
    <AssignmentCell>
      <StyledInput
        id={inputId}
        type="text"
        defaultValue={getScore(false)}
        inputProps={{
          style: {
            color: inputErrorState ? red : 'inherit',
            paddingTop: '5px',
            paddingBottom: '5px',
          },
        }}
        style={{ display: inputState ? 'block' : 'none' }}
      />
      <TextWrapper>
        <Text variant="md" center>
          {scoreText}
        </Text>
      </TextWrapper>
      <AssignmentButton
        disabled={classStateLoading && inputState}
        onClick={() => {
          if (inputState) {
            const input = document.getElementById(inputId) as HTMLInputElement;
            saveScore(input.value ?? '');
          } else {
            if (debugPrint) console.log(`*** button set input state true ${student.id}`);
            setInputState(true);
          }
        }}
      >
        {inputState ? 'Save' : 'Edit'}
      </AssignmentButton>
    </AssignmentCell>
  );
};

const TextWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'end',
});

const AssignmentCell = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignContent: 'end',
  justifyContent: 'center',
});

const StyledInput = styled(TextField)({
  width: '4rem',
  marginRight: '0.5rem',
});

const AssignmentButton = styled(Button)({
  alignItems: 'center',
  justifyContent: 'center',
  paddingLeft: '0.5rem',
  paddingRight: '0.5rem',
  paddingTop: '0.5rem',
  paddingBottom: '0.5rem',
  marginRight: '0.5rem',
  marginLeft: '0.5rem',
});
