// @ts-strict-ignore
import { ModuleDefinition } from '../../../types/routes/module';
import { ModuleEventWithUserClass } from '../../../types/models';
import { useSelector } from 'react-redux';
import { Dictionary, map } from 'lodash';
import React from 'react';
import { SubjectLeafProps } from '../../components/SubjectTree';
import { ModuleSummaryTable } from './moduleSummaryTable';
import { RowDetails, SubjectRowLabel } from '../../components/StyledComponents';

export interface SummaryReportLeafData {
  eventsByStudent: Dictionary<ModuleEventWithUserClass[]>;
  lastEventByStudent: Dictionary<ModuleEventWithUserClass>;
}

export const SubjectSummaryReport = ({
  subjectModules,
  currentClass,
  leafData,
}: SubjectLeafProps<SummaryReportLeafData>) => {
  const modules = useSelector((state) => state.module.definitions.modules);
  const leafModules: ModuleDefinition[] = modules.filter((module) => subjectModules.indexOf(module.id) != -1);

  // Use localStorage instead of REACT state mechanism
  const statePath = (moduleId: string) =>
    currentClass ? `class/${currentClass.id}/module-state/${moduleId}` : `shared/module-state/${moduleId}`;
  const getState = (moduleId: string): { open: boolean } => {
    const item = window.localStorage.getItem(statePath(moduleId));
    return item ? JSON.parse(item) : { open: false };
  };

  const moduleClickHandler = (moduleId: string) => {
    const state = getState(moduleId);
    state.open = !state.open;
    window.localStorage.setItem(statePath(moduleId), JSON.stringify(state));
  };

  if (leafModules.length > 0)
    return (
      <>
        {map(leafModules, (module) => {
          const isOpen = getState(module.id).open ? { open: true } : {};
          return (
            <RowDetails key={module.id} {...isOpen}>
              <SubjectRowLabel onClick={() => moduleClickHandler(module.id)}>
                {module.name.toUpperCase()}
              </SubjectRowLabel>
              <ModuleSummaryTable
                currentClass={currentClass}
                module={module}
                eventsByStudent={leafData.eventsByStudent}
                isOpen={getState(module.id).open}
              />
            </RowDetails>
          );
        })}
      </>
    );
};
